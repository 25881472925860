@import '../_styles/variables';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $index-section-content;
  background-position: top left;
  background-size: 70%;
  background-image: url('../../_public/Biometrics.png');
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 390px) and (max-width: 430px) {
    padding-top: 0;
  }

  &:before {
    content: ' ';
    width: 100%;
    height: $layout-desktop-navigation-height;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

}


.list {
  display: grid;
  align-content: center;

  @include mediumUp() {
    gap: 28px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include medium() {
    padding-left: 0;
  }

  @include small-early() {
    grid-template-columns: 100%;
  }

  @include small() {
    gap: 28px;
    grid-template-columns: 100%;
  }
}


.cardSmall {
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  background: $pale-transparent-background;
  border: 1px solid $pale-transparent-border;
  backdrop-filter: blur(20px);
  border-radius: $border-radius-sm;

  @include small() {
    padding: 32px;
  }
}


.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 28px;
  z-index: 1;

  .heroTitle {
    max-width: 1150px;

    u {
      background: linear-gradient(to left top, $color-brand-raspberry-300 0%, $color-brand-raspberry-500 100%);
      background-clip: text;
    }

    i {
      width: 190px;
      height: 10px;
      display: inline-block;
    }

    span {
      @include transition(0s, all);
      transition-delay: 0s;

      position: relative;
      display: inline-block;
      opacity: 0;
      transform: translateY(30px);

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * $transition-delay-default;
        }
      }
    }

    @include medium() {
      max-width: 880px;
    }

    @include small-early() {

      i,
      br {
        display: none;
      }
    }

    @include small() {

      i,
      br {
        display: none;
      }
    }
  }

  .iconContainer {
    display: flex;
    gap: 40px;
    margin-top: 5px;

    a {
      display: inline-block;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.5);
      }
    }
  }

  .heroDescription {
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include mediumUp() {
      padding-left: $layout-section-left-padding;
    }

    @include small() {
      h2 {
        margin-top: -12px;
      }
    }
  }
}

.mobileBlackBgShape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(181deg,
      rgba(16, 8, 38, 0.75) 0%,
      rgba(16, 8, 38, 0.3) 31%,
      rgba(16, 8, 38, 0) 100%);

  @include mediumUp() {
    display: none;
  }
}

// .bgShapes {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 0;
//   overflow: hidden;
//   filter: blur(70px);

//   @include small() {
//     opacity: 0.5;
//   }

//   .bgShapeA {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 50%;
//     height: 100%;
//     background: linear-gradient(
//       181deg,
//       rgba(16, 8, 38, 0.75) 0%,
//       rgba(16, 8, 38, 0.3) 31%,
//       rgba(16, 8, 38, 0) 100%
//     );
//   }

//   .bgShapeB {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     background: radial-gradient(50% 47.71% at 100% 60.77%, #d55cff 0%, rgba(32, 24, 68, 0) 100%);
//     mix-blend-mode: hard-light;
//     opacity: 0.4;
//   }

//   .bgShapeC {
//     position: absolute;
//     top: 0;
//     left: 25%;
//     width: 50%;
//     height: 100%;
//     background: radial-gradient(50% 50% at 50% 50%, #d55cff 0%, rgba(32, 24, 68, 0) 100%);
//     opacity: 0.2;
//   }
// }

.heroBlueBgShape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $canvas-background;
  z-index: -1;
}

/*
 * Animations
 */
.heroTitle {
  &[data-inview='true'] {
    span {
      @include transition(1.8s, opacity, transform);
      opacity: 1;
      transform: translateY(0);
      margin-bottom: 0;
    }
  }
}

$transition-extra-delay: 0.5s;

.heroDescription {

  h2,
  a {
    @include transition(0s, all);
    transition-delay: 0s;
    opacity: 0;
    transform: translateY(30px);
  }

  &[data-inview='true'] {

    h2,
    a {
      @include transition(1.8s, opacity, transform);

      opacity: 1;
      transform: translateY(0);
      margin-bottom: 0;
    }

    h2 {
      transition-delay: 6 * $transition-delay-default + $transition-extra-delay;
    }

    a {
      &:nth-child(1) {
        transition-delay: 7 * $transition-delay-default + $transition-extra-delay;
      }

      &:nth-child(2) {
        transition-delay: 8 * $transition-delay-default + $transition-extra-delay;
      }
    }
  }
}