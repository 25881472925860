@import '../_styles/variables';

.container {
  opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $index-bg-canvas;
}
